import React from 'react';

import Layout from 'components/layout';
import styled from 'styled-components/macro';
import SEO from 'components/SEO';
import { theme } from 'styled-tools';
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect';

const PageContent = styled.div`
  padding-top: 4rem;
  background-color: #000000;

  .error-text {
    color: #cc183c;
  }
`;

const MainContainer = styled.div`
  font-family: ${theme('fonts.title')};
  margin: 0 auto;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 60px 20px;
  justify-content: center;
  align-items: center;
`;

const ContentHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`;

const ContentParagraph = styled.div`
  margin-bottom: 15px;

  span {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    color: #ffbc6c;
  }
`;

const HeaderText = styled.span`
  font-size: 34px;
`;

const ParagraphText = styled.span`
  font-size: 24px;
  line-height: 28px;

  a {
    color: #ffbc6c;
  }
`;

const responseText = React.createRef();
const headerText = React.createRef();

class PasswordResetPage extends React.PureComponent {
  componentDidMount() {
    confirmEmail();
  }

  render() {
    return (
      <Layout>
        <SEO title='Confirmation' />
        <PageContent>
          <MainContainer>
            <ContentHeader>
              <HeaderText ref={headerText}></HeaderText>
            </ContentHeader>
            <ContentParagraph>
              <ParagraphText ref={responseText}></ParagraphText>
            </ContentParagraph>
          </MainContainer>
        </PageContent>
      </Layout>
    );
  }
}

export default PasswordResetPage;

function parseErrorResponse(errorResponseString) {
  var errorMessages = [];
  if (errorResponseString != null && errorResponseString !== '') {
    var parsedError = JSON.parse(errorResponseString);
    if (parsedError.error) {
      if (parsedError.error.errors) {
        errorMessages = parsedError.error.errors.map(function (errorObj) {
          return errorObj.message;
        });
      } else if (parsedError.error.message) {
        errorMessages = [parsedError.error.message];
      }
    }
  }

  return errorMessages.join('\n');
}

function displayStatusMessage(isSuccess, message) {
  var responseContainer = responseText.current;
  var headerContainer = headerText.current;
  headerContainer.innerText = 'Email Confirmed!';

  responseContainer.innerText = message;
  if (!isSuccess) {
    headerContainer.innerText = 'Email Unconfirmed.';
    responseContainer.classList.add('error-text');
  }
}
let xhr;
if (typeof XMLHttpRequest !== `undefined`) {
  xhr = new XMLHttpRequest();
}
function onreadystatechange() {
  if (xhr.readyState == 4 && xhr.status == 200) {
    var successText = 'Head back to the app and keep investing.';
    displayStatusMessage(true, successText);
  } else {
    var failureText =
      parseErrorResponse(xhr.responseText) === ''
        ? 'Unable to confirm your email address.  Please contact support'
        : parseErrorResponse(xhr.responseText);

    displayStatusMessage(false, failureText);
  }
}

function confirmEmail() {
  var url = window.location.href;
  var paramsRaw = url.split('?');
  var params = paramsRaw[paramsRaw.length - 1].split('&');
  var token = null;

  for (var i = 0; i < params.length; i++) {
    var m = params[i].split('=');
    if (m[0] === 'token') {
      token = m[1];
    }
  }

  if (token == null) {
    displayStatusMessage(false, 'Invalid token');
    return null;
  }

  var passwordResetPayload = {
    token: token,
  };
  xhr.open('PATCH', 'https://api.dough.com/confirmation', true);
  xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8"');
  xhr.onreadystatechange = onreadystatechange;
  xhr.send(JSON.stringify(passwordResetPayload));
}
